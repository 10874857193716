import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFormInstance, FormInstanceResponse } from './types';

export interface StandaloneSliceTypes {
  formData: null | FormInstanceResponse;
  loading: boolean;
  error: null;
}

export const initialState: StandaloneSliceTypes = {
  formData: null,
  loading: true,
  error: null,
};

export const standaloneFormSlice = createSlice({
  name: 'standaloneForms',
  initialState,
  reducers: {
    fetchFormDataRequest(state, _action: PayloadAction<fetchFormInstance>) {
      state.loading = true;
    },
    fetchFormDataSuccess(state, action: PayloadAction<FormInstanceResponse>) {
      state.loading = false;
      state.formData = action.payload;
    },
    mapFormDataWithFormID(state, action: PayloadAction<{ id: string | number }>) {
      state.loading = false;
      state.formData = {
        form_version: {
          form_id: Number(action.payload.id),
          form: {
            id: Number(action.payload.id),
          },
        },
      };
    },
  },
});

export const { fetchFormDataRequest, fetchFormDataSuccess, mapFormDataWithFormID } = standaloneFormSlice.actions;

export default standaloneFormSlice.reducer;
