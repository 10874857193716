import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AcceptTimePayload } from './types';

export const initialState = {};

export const AcceptProposedTimeSlice = createSlice({
  name: 'accept-propsed-time-scheduling',
  initialState,
  reducers: {
    acceptNewTime(_state, _action: PayloadAction<AcceptTimePayload>) {},
  },
});

export const { acceptNewTime } = AcceptProposedTimeSlice.actions;
export default AcceptProposedTimeSlice.reducer;
