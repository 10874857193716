import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InterviewDeclinePayload } from '@containers/Scheduling/types';

export const initialState = {};
export const InterviewDeclineSlice = createSlice({
  name: 'interview-decline-scheduling',
  initialState,
  reducers: {
    interviewDecline(_state, _action: PayloadAction<InterviewDeclinePayload>) {},
  },
});

export const { interviewDecline } = InterviewDeclineSlice.actions;
export default InterviewDeclineSlice.reducer;
