/**
 *
 * AuthContext
 *
 */

import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  getEmailOtp,
  getPhoneFirebaseOtp,
  initialize,
  verifyFirebaseOtp,
  verifyEmailOtp,
  initializeSocialLogin,
  unAuthenticate,
} from '@containers/Auth/slice';
import { selectAuth, selectCandidate, selectQueryParams, selectUser } from '@containers/Auth/selectors';
import type { Email, Phone } from '@components/Login/types';
import type { SagaCallback } from '@store/types';
import type { AuthUserMe, Candidate, QueryParams, User } from '@containers/Auth/types';
import { SocialLoginProviderName } from '@containers/Auth/types';
import { useFeatureValue, useGrowthBook } from '@growthbook/growthbook-react';
import { growthBookFeatureFlags } from '@utils/featureFlags';
import Mixpanel from 'mixpanel-browser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sentrySetUser } from '@utils/sentry';
import { candidateAuthenticated } from '@utils/mixpanel/mixpanelActions';

export interface Tokens {
  accessToken: string | null;
  customToken?: string | null;
  hash_token?: string | null;
}

export type AuthContextType = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isInitialized: boolean;
  triggerEmailOtp: (_userName: Email, _callback: SagaCallback) => void;
  triggerPhoneFirebaseOtp: (_phoneNumber: Phone, _callback: SagaCallback) => void;
  triggerVerifyEmailOtp: (_code: number, _callback?: SagaCallback) => void;
  triggerFirebaseVerifyOtp: (_code: string, _callback?: SagaCallback) => void;
  triggerUnAuthenticate: () => void;
  phone?: Phone;
  username?: Email;
  verificationCodeRequested: boolean;
  user?: User;
  queryParams?: QueryParams;
  socialLoginProviders?: SocialLoginProviderName;
  initiateSocialLogin: (_name: SocialLoginProviderName, _callback: SagaCallback) => void;
  currentUser?: AuthUserMe | undefined;
  tokens?: Tokens;
  redirectPostAuthentication?: () => void;
  candidate?: Candidate | null;
};

export const authContextInitialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  triggerEmailOtp: (_userName: Email, _callback: SagaCallback) => {},
  triggerPhoneFirebaseOtp: (_phoneNumber: Phone, _callback: SagaCallback) => {},
  initiateSocialLogin: (_name: SocialLoginProviderName) => {},
  triggerVerifyEmailOtp: (_code: number, _callback?: SagaCallback) => {},
  triggerFirebaseVerifyOtp: (_code: string, _callback?: SagaCallback) => {},
  triggerUnAuthenticate: () => {},
  phone: '' as Phone,
  username: '' as Email,
  verificationCodeRequested: false,
  isInitialized: false,
  tokens: { accessToken: '' },
  candidate: null,
};

const AuthContext = React.createContext<AuthContextType>(authContextInitialState);

interface AuthProviderProps {
  children: React.ReactNode | React.ReactElement;
}

function AuthProvider(props: AuthProviderProps) {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const queryParams = useAppSelector(selectQueryParams);
  const growthBook = useGrowthBook();
  const enableMixPanel = useFeatureValue(growthBookFeatureFlags.MIX_PANEL_ENABLED, false);
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const candidate = useAppSelector(selectCandidate);

  const tokens = useMemo<Tokens>(() => {
    const { accessToken, customToken, idToken, hash_token } = state;
    return { accessToken, customToken, idToken, hash_token };
  }, [state]);

  const triggerEmailOtp = useCallback(
    (username: Email, callback: SagaCallback) => {
      dispatch(getEmailOtp({ username, callback }));
    },
    [dispatch]
  );

  const triggerPhoneFirebaseOtp = useCallback(
    (phone: Phone, callback: SagaCallback) => {
      dispatch(getPhoneFirebaseOtp({ phone, callback }));
    },
    [dispatch]
  );

  const triggerVerifyEmailOtp = useCallback(
    (code: number, callback?: SagaCallback) => {
      dispatch(
        verifyEmailOtp({
          username: user?.phone || user?.username,
          code,
          callback,
        })
      );
    },
    [user, dispatch]
  );

  const triggerFirebaseVerifyOtp = useCallback(
    (code: string, callback?: SagaCallback) => {
      dispatch(
        verifyFirebaseOtp({
          code,
          callback,
        })
      );
    },
    [dispatch]
  );

  const initiateSocialLogin = (name: SocialLoginProviderName, callback: SagaCallback) => {
    dispatch(
      initializeSocialLogin({
        socialLoginProviderName: name,
        callback: callback,
      })
    );
  };

  const redirectPostAuthentication = () => {
    if (queryParams?.from) {
      const path = `${queryParams?.from}`;
      navigate(path, { replace: true });
    } else if (queryParams?.redirect_url) {
      navigate('/redirect');
    }
  };

  const triggerUnAuthenticate = async () => {
    dispatch(unAuthenticate());
  };

  const value = useMemo(
    () => ({
      user,
      queryParams,
      isAuthenticated: state.authenticated,
      isAuthenticating: state.authenticating,
      isInitialized: state.initialized,
      triggerEmailOtp,
      triggerPhoneFirebaseOtp,
      initiateSocialLogin,
      triggerVerifyEmailOtp,
      triggerFirebaseVerifyOtp,
      username: user?.username,
      phone: user?.phone,
      verificationCodeRequested: state?.verificationCodeRequested,
      tokens,
      redirectPostAuthentication,
      triggerUnAuthenticate,
      candidate,
    }),
    [
      user,
      queryParams,
      state.authenticated,
      state.authenticating,
      state.initialized,
      state?.verificationCodeRequested,
      triggerEmailOtp,
      triggerPhoneFirebaseOtp,
      triggerVerifyEmailOtp,
      triggerFirebaseVerifyOtp,
      tokens,
      triggerUnAuthenticate,
      candidate,
    ]
  );

  useEffect(() => {
    dispatch(initialize());
  }, []);

  useEffect(() => {
    const tid = search.get('tid') || user?.tenant_id;
    growthBook?.setAttributes({
      ...growthBook.getAttributes(),
      tenant_id: tid ? Number(tid) : null,
      user_id: user?.user_id ? Number(user?.user_id) : null,
    });
    sentrySetUser(user);
    if (value.isAuthenticated) {
      candidateAuthenticated();
    }
  }, [value.isAuthenticated, value.isAuthenticating]);

  useEffect(() => {
    const enabled = enableMixPanel && process.env.NODE_ENV === 'production';
    Mixpanel.init(process.env.MIXPANEL_TOKEN as string, {
      ignore_dnt: true,
      debug: false,
      opt_out_tracking_by_default: !enabled,
      track_pageview: false,
      loaded(mixpanel) {
        growthBook.setAttributes({
          ...growthBook.getAttributes(),
          id: mixpanel.get_distinct_id(),
        });
      },
    });
    if (enabled && Mixpanel && typeof Mixpanel.opt_in_tracking === 'function') {
      Mixpanel?.opt_in_tracking();
    } else if (typeof Mixpanel.opt_out_tracking === 'function') {
      Mixpanel?.opt_out_tracking();
    }
  }, [enableMixPanel]);

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export const AuthConsumer = AuthContext.Consumer;

export { AuthProvider };

export default AuthContext;
