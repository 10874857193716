import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateParticipationStatusPayload } from './types';

export const initialState = {};

export const joinInterviewSlice = createSlice({
  name: 'joinInterview',
  initialState,
  reducers: {
    updateMeetingParticipationStatusRequest(_state, _action: PayloadAction<UpdateParticipationStatusPayload>) {},
  },
});

export const { updateMeetingParticipationStatusRequest } = joinInterviewSlice.actions;
export default joinInterviewSlice.reducer;
