import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Candidate, CandidateInput, CandidateState } from './types';
import { SagaCallback } from '@store/types';

export const initialState: CandidateState = {
  candidate: null,
};

export const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    updateCandidateRequest: (_state, _action: PayloadAction<{ data: CandidateInput; callback?: SagaCallback }>) => {},
    updateCandidateSuccess: (state, action: PayloadAction<{ data: Candidate }>) => {
      state.candidate = action.payload.data;
    },
  },
});

export const { updateCandidateRequest, updateCandidateSuccess } = candidateSlice.actions;

export default candidateSlice.reducer;
