import { put, call, takeLatest, fork, all } from 'redux-saga/effects';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { PayloadAction } from '@reduxjs/toolkit';
import { BookSlotResponse, GetBookedSlot, GetMeetingSlots, MeetingSlotsResponse, ResponseData } from './type';
import { bookSlotRequest, fetchMeetingSlotsRequest, fetchMeetingSlotsSuccess } from './slice';
import { BOOK_MEETING_SLOT, GET_MEETING_SLOTS } from './queries';

export function* fetchMeetingSlotsWorker({ payload }: PayloadAction<GetMeetingSlots>): Generator {
  try {
    const { meetingId } = payload.data;

    const queryResponse = (yield call(postData, {
      queryString: GET_MEETING_SLOTS,
      payload: { meeting_id: meetingId },
      spreadPayload: true,
    })) as ResponseData;

    const meetingSlotsResponse: MeetingSlotsResponse = queryResponse?.data?.sch_get_meeting_slots?.[0];

    if (meetingSlotsResponse) {
      yield put(fetchMeetingSlotsSuccess(meetingSlotsResponse.data.slots));

      if (payload.callback?.onSuccess) {
        yield call(payload.callback.onSuccess, meetingSlotsResponse.data);
      }
    } else {
      throw new Error('An error while fetching meeting slots.');
    }
  } catch (error) {
    yield call(catchError, { title: 'Meeting Slots Worker', error: error as Error });
    if (payload.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* bookSlotSaga({ payload }: PayloadAction<GetBookedSlot>): Generator {
  try {
    const response = (yield call(postData, {
      queryString: BOOK_MEETING_SLOT,
      payload: payload?.data,
      spreadPayload: true,
    })) as BookSlotResponse;

    if (payload.callback?.onSuccess && response?.data?.sch_confirm_meeting_slot?.message === 'Success') {
      yield call(payload.callback.onSuccess, response?.data?.sch_confirm_meeting_slot);
    } else {
      throw new Error(`An error occurred while booking a slot ${response?.errors[0]?.message}`);
    }
  } catch (error) {
    yield call(catchError, { title: 'Meeting Slots booking', error: error as Error });
    if (payload.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

// Watcher saga for fetchMeetingSlotsRequest
export function* watchFetchMeetingSlots() {
  yield takeLatest(fetchMeetingSlotsRequest.type, fetchMeetingSlotsWorker);
}

export function* bookSlotWatcherSaga() {
  yield takeLatest(bookSlotRequest.type, bookSlotSaga);
}

export function* meetingSlotsRootSaga() {
  yield all([fork(watchFetchMeetingSlots), fork(bookSlotWatcherSaga)]);
}
