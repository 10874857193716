import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SchedulingInitialState, MeetingRequestPayload, MeetingPayload } from './types';

export const initialState: SchedulingInitialState = {
  request: {},
  data: {
    meeting: {},
    participant: {},
  },
  meetingState: {
    isMeetingCompleted: false,
    isMeetingCancelled: false,
    isCurrentUserParticipant: false,
    isMeetingStarted: false,
    isMeetingDeclined: false,
    meetingConditionType: null,
  },
};

export const schedulingSlice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    fetchMeeting(_state, _action: PayloadAction<MeetingRequestPayload>) {},
    storeMeeting(state, action: PayloadAction<MeetingPayload>) {
      const {
        meeting,
        participant,
        isMeetingStarted,
        isMeetingCancelled,
        isMeetingCompleted,
        isMeetingDeclined,
        meetingConditionType,
      } = action.payload;

      state.data = {
        meeting,
        participant,
      };
      state.meetingState = {
        isMeetingStarted: isMeetingStarted,
        isMeetingCancelled: isMeetingCancelled,
        isMeetingCompleted: isMeetingCompleted,
        isMeetingDeclined: isMeetingDeclined,
        isCurrentUserParticipant: !!participant,
        meetingConditionType: meetingConditionType,
      };
    },
  },
});

export const { fetchMeeting, storeMeeting } = schedulingSlice.actions;
export default schedulingSlice.reducer;
