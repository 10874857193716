import { gql } from '@apollo/client';

export const GET_FORM_INSTANCE_DATA = gql`
  query getFormInstanceData($id: Int!) {
    frm_form_instance(where: { id: { _eq: $id } }) {
      id
      submitted_by
      form_version {
        form_id
        form {
          name
          id
        }
      }
    }
  }
`;
