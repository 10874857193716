import { createSelector } from '@reduxjs/toolkit';
import { assessmentSlice } from '@containers/Assessment/slice';
import { RootState } from '@store/index';
import { selectQueryParams } from '@containers/Auth/selectors';

const selectAssessmentState = createSelector(
  (state: RootState) => state,
  (state) => state[assessmentSlice.name]
);
export const selectRequest = createSelector(selectAssessmentState, (state) => state.request);

export const selectData = createSelector(selectAssessmentState, (state) => state.data);

export const selectAssessment = createSelector(selectData, (data) => data.assessment);
export const selectAssessmentError = createSelector(selectData, (data) => data.assessmentError);
export const selectAssessmentSection = createSelector(selectData, (data) => data.assessmentSection);
export const selectCurrentAssessmentSectionId = createSelector(selectData, (data) => data.currentAssessmentSectionId);

export const selectTestInstanceId = createSelector(selectQueryParams, (queryParams) =>
  queryParams?.test_instance_id ? Number(queryParams.test_instance_id) : undefined
);

export const selectNextQuestionId = createSelector(selectData, (data) => data.next_question_id);

export const selectStartSectionResponse = createSelector(selectData, (data) => data.startSectionResponse);

export const selectQuestionIds = createSelector(selectData, (data) => data.questionsIds);

export const selectCurrentQuestion = createSelector(selectData, (data) => data.currentQuestion);

export const selectParsedMaxSectionTime = createSelector(selectData, (data) => data.parsedMaxSectionTime);

export const selectSubmittedAnswerResponse = createSelector(selectData, (data) => data.answer);

export const selectTcfMeasurement = createSelector(selectData, (data) => data.tcfMeasurement);

export const selectAppState = createSelector(selectData, (data) => data.appState);

export const selectAnswers = createSelector(selectData, (data) => data.answers);
export const selectAllQuestion = createSelector(selectData, (data) => data.questions);
