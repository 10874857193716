import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  CandidateProfile, 
  CandidateProfileState, 
  CreateProfileInput, 
  CreateFaceAuthInput,
} from './types';
import { SagaCallback } from '@store/types';

export const initialState: CandidateProfileState = {
  profile: null,
  candidateId: null,
};

export const candidateProfileSlice = createSlice({
  name: 'candidateProfile',
  initialState,
  reducers: {
    createProfileRequest: (_state, _action: PayloadAction<{ data: CreateProfileInput; callback?: SagaCallback }>) => {},
    createProfileSuccess: (state, action: PayloadAction<{ data: CandidateProfile }>) => {
      state.profile = action.payload.data;
      state.candidateId = action.payload.data.candidate_id;
    },
    clearCandidateProfile: (state) => {
      state.profile = null;
      state.candidateId = null;
    },
    createFaceAuthRequest: (_state, _action: PayloadAction<{ data: CreateFaceAuthInput; callback?: SagaCallback }>) => {},
    createFaceAuthSuccess: (_state, _action: PayloadAction<{ data: { auth_id: string; status: string } }>) => {},
  },
});

export const { 
  createProfileRequest, 
  createProfileSuccess, 
  clearCandidateProfile,
  createFaceAuthRequest,
  createFaceAuthSuccess
} = candidateProfileSlice.actions;

export default candidateProfileSlice.reducer;