import { gql } from '@apollo/client';

export const REGISTER_CANDIDATE_TO_DRIVE = gql`
  mutation driveGuestRegistration($payload: [sch_register_guest_input]!) {
    sch_register_guest(data: $payload) {
      data {
        drive_occurrence_guest {
          drive_schedule_id
          guest_id
          id
          pay_item {
            id
            order_items {
              order_id
            }
          }
        }
        guest {
          email
          external_id
          id
          name
          user_id
        }
      }
      success_count
      error_count
    }
  }
`;
