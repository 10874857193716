import { isValidDataKey } from '@utils/sagaResponseValidator';
import { SubscriptionLoobyDriveResponse } from './types';

export const isSchDriveLobbyMeeting = (data: unknown): data is SubscriptionLoobyDriveResponse['data'] => {
  return isValidDataKey<SubscriptionLoobyDriveResponse['data'], 'sch_drive_lobby'>(
    data,
    'sch_drive_lobby',
    true // Set to `false` if it's expected to be a single object
  );
};
