import { PayloadAction } from '@reduxjs/toolkit';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { call, debounce, fork, put, takeLatest } from 'redux-saga/effects';
import { CANDIDATE_CREATE_PROFILE_MUTATION, CREATE_FACE_AUTH_REQUEST_MUTATION } from './queries';
import { createFaceAuthRequest, createFaceAuthSuccess, createProfileRequest, createProfileSuccess } from './slice';
import {
  CreateFaceAuthPayload,
  CreateFaceAuthResponseData,
  CreateProfilePayload,
  CreateProfileResponseData,
} from './types';

export function* createProfileSagaWorker({ payload }: PayloadAction<CreateProfilePayload>): Generator {
  const { data, callback } = payload;

  try {
    const queryResponse = (yield call(postData, {
      queryString: CANDIDATE_CREATE_PROFILE_MUTATION,
      payload: {
        external_id: data.external_id,
        candidate_email: data.candidate_email,
      },
      spreadPayload: true,
    })) as CreateProfileResponseData;

    const profileData = queryResponse?.data?.ca_create_profile;

    if (profileData && profileData.success) {
      yield put(createProfileSuccess({ data: profileData.data }));

      if (callback?.onSuccess) {
        yield call(callback.onSuccess, profileData.data);
      }
    } else {
      const err = Array.isArray(profileData?.error_message)
        ? profileData?.error_message?.[0]
        : (profileData?.error_message as string);
      throw Error(`Failed to create candidate profile : ${err}`);
    }
  } catch (error) {
    yield call(catchError, { title: 'Create profile worker', error: error as Error, skipToast: true });
    if (callback?.onError) {
      yield call(callback.onError, error as Error);
    }
  }
}

export function* createFaceAuthSagaWorker({ payload }: PayloadAction<CreateFaceAuthPayload>): Generator {
  const { data, callback } = payload;

  try {
    const queryResponse = (yield call(postData, {
      queryString: CREATE_FACE_AUTH_REQUEST_MUTATION,
      payload: {
        file: data.file,
        file_name: data.file_name,
        candidate_id: parseInt(data.candidate_id.toString(), 10),
        description: data.description,
        redirect_uri: data.redirect_uri,
      },
      spreadPayload: true,
    })) as CreateFaceAuthResponseData;

    const authData = queryResponse?.data?.ca_create_auth_request;

    if (authData && authData.success) {
      yield put(createFaceAuthSuccess({ data: authData.data }));

      if (callback?.onSuccess) {
        yield call(callback.onSuccess, authData.data);
      }
    } else {
      const err = Array.isArray(authData?.error_message) ? authData?.error_message?.[0] : authData?.error_message;
      throw Error(`Failed to create candidate auth request : ${err}`);
    }
  } catch (error) {
    yield call(catchError, { title: 'Create face auth request worker', error: error as Error, skipToast: true });
    if (callback?.onError) {
      yield call(callback.onError, error as Error);
    }
  }
}

export function* createProfileWatcher() {
  yield debounce(500, createProfileRequest.type, createProfileSagaWorker);
}

export function* createFaceAuthWatcher() {
  yield takeLatest(createFaceAuthRequest.type, createFaceAuthSagaWorker);
}

export function* biometricRootSaga() {
  yield fork(createProfileWatcher);
  yield fork(createFaceAuthWatcher);
}
