import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProposeNewTimePayload } from './types';

export const initialState: object = {};

export const ProposeNewTimeSlice = createSlice({
  name: 'propose-newtime-scheduling',
  initialState,
  reducers: {
    proposeNewTime(_state, _action: PayloadAction<ProposeNewTimePayload>) {},
    fetchBusySlots(_state, _action: PayloadAction<ProposeNewTimePayload>) {},
    storeBusySlots(_state, _action: PayloadAction<ProposeNewTimePayload>) {},
  },
});

export const { storeBusySlots, proposeNewTime, fetchBusySlots } = ProposeNewTimeSlice.actions;
export default ProposeNewTimeSlice.reducer;
