import { DateTime } from 'luxon';
import { catchException } from './catchException';

export const parseDateTime = (date: string) => (date && DateTime.fromISO(date).isValid ? DateTime.fromISO(date) : null);

export const parseJSDateTime = (date: Date) =>
  date && DateTime.fromJSDate(date).isValid ? DateTime.fromJSDate(date) : null;

export const getCurrentDate = (): DateTime => {
  const testDate = '2022-12-07T12:00:00+05:30';
  const env = process.env.NODE_ENV;

  if (env === 'test' || process.env.STORYBOOK_APP_ENV === 'storybook') {
    return parseDateTime(testDate) || DateTime.now();
  }

  return DateTime.now();
};

export const formatDate = (dateString: string): string => {
  const date = parseDateTime(dateString);
  if (!date) return 'Invalid date (formatDate)';
  const day = date.day;
  const month = date.monthLong;
  const weekday = date.weekdayLong;
  // const month = date.toFormat('MMMM'); // Full month name (e.g., "January")
  // const weekday = date.toFormat('cccc'); // Full weekday name (e.g., "Saturday")

  return `${day} ${month}, ${weekday}`;
};

export const formatIsoDateToLongDate = (isoDate: string): string => {
  const date = parseDateTime(isoDate);
  if (!date) return 'Invalid ISO Date';
  return date.toFormat('d MMMM yyyy');
};

export const formatTimeRangeInIST = (startDate: string, endDate: string): string => {
  const start = parseDateTime(startDate);
  const end = parseDateTime(endDate);

  if (!start || !end) {
    return 'Invalid date range';
  }

  const startTime = start.toFormat('h:mm a');
  const endTime = end.toFormat('h:mm a');
  const zone = start.toFormat('ZZZZ');

  return `${startTime} - ${endTime} ${getMappedGmtTimezoneToShortName(zone)}`;
};

export function getMappedGmtTimezoneToShortName(zone: string) {
  if (zone === 'GMT+5:30' || zone === 'Asia/Kolkata' || zone === 'Asia/Calcutta') {
    return 'IST';
  }
  return zone;
}

export const getCurrentTimeInUnit = (unit: 'seconds' | 'milliseconds' | 'minutes' | 'hours' = 'seconds'): number => {
  const now = DateTime.now().toMillis(); // Get current time in milliseconds

  switch (unit) {
    case 'milliseconds':
      return now; // Already in milliseconds
    case 'minutes':
      return Math.floor(now / (60 * 1000)); // Convert milliseconds to minutes
    case 'hours':
      return Math.floor(now / (60 * 60 * 1000)); // Convert milliseconds to hours
    case 'seconds':
    default:
      return Math.floor(now / 1000); // Convert milliseconds to seconds
  }
};

export const systemTimezone = DateTime.now().zoneName;

export function formatCustomLongDateWithEndTime(dateString: string, timeZone?: string): string {
  if (!dateString) {
    return `Invalid Date string ${dateString}`;
  }
  const date = parseDateTime(dateString)?.setZone(timeZone || systemTimezone);

  if (!date || !date.isValid) {
    return `Invalid Date string ${dateString}`;
  }

  // Format the date as "DD MM YYYY at H:mm AM/PM"
  const formattedDate = date.toFormat("dd LLLL yyyy 'at' h:mm a");

  // Add timezone abbreviation
  const timezoneAbbreviation = getMappedGmtTimezoneToShortName(timeZone || systemTimezone);

  return `${formattedDate} ${timezoneAbbreviation}`;
}

export const getTimezoneLabel = (
  dateString: string,
  formatType: string = 'long',
  fallbackTimezone: string = systemTimezone
): string => {
  const parsedDate = parseDateTime(dateString);
  const zone = parsedDate?.toFormat('ZZZZ');
  const shortTimezoneName = getMappedGmtTimezoneToShortName(zone || fallbackTimezone);
  if (formatType === 'short') {
    return shortTimezoneName;
  }

  return getTimeZoneFullName(shortTimezoneName);
};

export function getTimeZoneFullName(abbreviation: string): string {
  const timeZones: { [key: string]: string } = {
    ADT: 'Atlantic Daylight Time',
    AKDT: 'Alaska Daylight Time',
    AKST: 'Alaska Standard Time',
    AST: 'Atlantic Standard Time',
    CDT: 'Central Daylight Time',
    CST: 'Central Standard Time',
    EDT: 'Eastern Daylight Time',
    EST: 'Eastern Standard Time',
    FJT: 'Fiji Time',
    GMT: 'Greenwich Mean Time',
    HADT: 'Hawaii-Aleutian Daylight Time',
    HAST: 'Hawaii-Aleutian Standard Time',
    IST: 'India Standard Time',
    JST: 'Japan Standard Time',
    MDT: 'Mountain Daylight Time',
    MST: 'Mountain Standard Time',
    NDT: 'Newfoundland Daylight Time',
    NST: 'Newfoundland Standard Time',
    PDT: 'Pacific Daylight Time',
    PST: 'Pacific Standard Time',
    SDT: 'Samoa Daylight Time',
    SST: 'Samoa Standard Time',
    UTC: 'Coordinated Universal Time',
  };

  return timeZones[abbreviation] || abbreviation;
}

export const getMinMaxDateHelper = (
  param: number | { minDate?: boolean; maxDate?: boolean; days?: number }
): { minDate: Date; maxDate: Date } | Date => {
  const today = getCurrentDate();

  if (typeof param === 'number') {
    const minDate = today.toJSDate();
    const maxDate = today.plus({ days: param }).toJSDate();
    return { minDate, maxDate };
  }

  if (typeof param === 'object') {
    const { minDate, maxDate, days } = param;

    if (minDate) {
      return today.toJSDate();
    }

    if (maxDate) {
      return today.plus({ days: days || 0 }).toJSDate();
    }
  }
  // return today.toJSDate();
  throw catchException.validationError('Invalid input for getMinMaxDate', {
    param,
    expected: "number or an object with 'minDate' or 'maxDate' keys",
  });
};

// Example Usage
// console.log(getMinMaxDate(7));
// Output: { minDate: [current_date], maxDate: [current_date + 7 days] }

// console.log(getMinMaxDate({ minDate: true }));
// Output: [current_date]

// console.log(getMinMaxDate({ maxDate: true, days: 7 }));
// Output: [current_date + 7 days]
