import { gql } from '@apollo/client';

export const UPDATE_MEETING_PARTICIPATION_STATUS =
  gql(`mutation updateMeetingParticipationStatus($payload: [sch_update_meeting_participant_status_input!]) {
    sch_update_meeting_participant_status(data: $payload){
      data
      success
     error_message
    }
  }
`);
