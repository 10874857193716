import { DocumentNode } from 'graphql';
import { eventChannel } from 'redux-saga';
import client from './apollo';
import { catchError } from './sentry';

interface SubscriptionVariables {
  [key: string]: unknown;
}
/**
 * TData and TVariables are generic type parameters.
 * TData: This is the type for the data you expect to be used in the subscription
 * TVariables: This is the type for the variables you send in the subscription request
 * The extends SubscriptionVariables means that whatever type is used for TVariables, it must be a subtype of SubscriptionVariables
 */

export interface SubscriptionResponse<T> {
  data?: T;
  error?: Error;
}

interface SubscriptionChannelParams<TVariables extends SubscriptionVariables> {
  query: DocumentNode;
  variables: TVariables;
}

export function createApolloSubscriptionChannel<TData, TVariables extends SubscriptionVariables>({
  query,
  variables,
}: SubscriptionChannelParams<TVariables>): ReturnType<typeof eventChannel> {
  return eventChannel<SubscriptionResponse<TData>>((emit) => {
    // Establish the Apollo subscription
    const subscription = client.subscribe<TData, TVariables>({ query, variables }).subscribe({
      next: (response) => {
        // console.log(response, '::receiving subscription');

        // Emit the received data
        emit({ data: response?.data as TData | undefined });
      },
      error: (error: Error) => {
        catchError({
          error,
          title: 'Apollo Subscription Error',
          // skipToast: false,
          extraScope: {
            key: 'subscription',
            value: 'failed',
          },
        });

        // Emit the error to the subscriber
        emit({ error });
      },
    });

    // Clean up the subscription when the channel is closed
    return () => {
      subscription.unsubscribe();
    };
  });
}
