import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CreateFeedbackInputPayload, CreateFeedbackResponse, FeedbackItem } from './type';
import { SagaCallback } from '@store/types';

export interface FeedbackState {
  feedbacks: FeedbackItem[];
  isLoading: boolean;
  error: string | null;
}

const initialState: FeedbackState = {
  feedbacks: [],
  isLoading: false,
  error: null,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    initializeFeedback: (state) => {
      return {
        ...state,
        ...initialState,
        isLoading: true,
      };
    },
    createFeedback: (_state, _action: PayloadAction<{ data: CreateFeedbackInputPayload; callback?: SagaCallback }>) => {
      // state.isLoading = true;
    },
    storeFeedbackResponse: (state, action: PayloadAction<CreateFeedbackResponse>) => {
      state.feedbacks = action.payload.data.canx_create_feedback;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { initializeFeedback, createFeedback, storeFeedbackResponse } = feedbackSlice.actions;

export default feedbackSlice.reducer;
