// helpers.ts
import { getFormattedMeetingStatus, meetingStatus } from '@utils/data/meetingStatus';
import { getCurrentDate, parseDateTime } from '@utils/dateHelpers';
import { getFormattedRsvpStatus, rsvpStatus } from '@utils/data/rsvpStatus';
import { MeetingParticipant, MeetingResponse, MeetingStates } from './types';
import { meetingParticipantRole } from '@utils/data/roles';
import { includes, find } from 'lodash';

export const findParticipant = (
  meeting: MeetingResponse,
  userId: number,
  participantId?: number | null
): MeetingParticipant | null => {
  return (
    find(meeting?.meeting_participants, (p) => {
      if (participantId) {
        return p?.id === participantId && p?.guest?.user_id === userId;
      }
      return p?.guest?.user_id === userId && p.participant_role === meetingParticipantRole?.GUEST;
    }) || null
  );
};

export const getIsMeetingStarted = (meeting: MeetingResponse): boolean => {
  const status = meeting && getFormattedMeetingStatus(meeting?.meeting_status)?.key;
  const meetingStartTime = parseDateTime(meeting.from);
  const searchParams: string = window.location.pathname;
  const isJoinInterviewPresent: boolean = searchParams.includes('join-interview');

  if (isJoinInterviewPresent) {
    return false;
  }

  if (meetingStartTime && meetingStartTime < getCurrentDate()) {
    return true;
  }

  return !includes(
    [meetingStatus?.SCHEDULED, meetingStatus?.DRAFT, meetingStatus?.CANCELLED, meetingStatus?.COMPLETED],
    status
  );
};

export const getIsMeetingCancelled = (meeting: MeetingResponse): boolean => {
  return getFormattedMeetingStatus(meeting.meeting_status)?.key === meetingStatus.CANCELLED;
};

export const getIsMeetingCompleted = (meeting: MeetingResponse): boolean => {
  const status = getFormattedMeetingStatus(meeting.meeting_status)?.key;
  const endTimestamp = parseDateTime(meeting.to);
  const startTimestamp = parseDateTime(meeting.from);

  if (startTimestamp && endTimestamp) {
    const endTimeWithBuffer = endTimestamp.plus({ minutes: 35 });
    return endTimeWithBuffer < getCurrentDate()
  }
  // Fallback to checking the status if timestamps are not available
  return status === meetingStatus.COMPLETED;
};

export const getIsMeetingDeclined = (participant: MeetingParticipant): boolean => {
  return getFormattedRsvpStatus(participant?.rsvp_status)?.key === rsvpStatus.DECLINED;
};

export function getMeetingStates(meeting: MeetingResponse, participant: MeetingParticipant): MeetingStates {
  return {
    isMeetingStarted: getIsMeetingStarted(meeting),
    isMeetingCancelled: getIsMeetingCancelled(meeting),
    isMeetingCompleted: getIsMeetingCompleted(meeting),
    isMeetingDeclined: getIsMeetingDeclined(participant),
  };
}

export const meetingType = (
  meeting: MeetingResponse,
  userId: number,
  participantId: number | null,
  participant: MeetingParticipant
): string => {
  const authorizedUser = findParticipant(meeting, userId, participantId);
  const { isMeetingStarted, isMeetingCancelled, isMeetingCompleted, isMeetingDeclined } = getMeetingStates(
    meeting,
    participant
  );
  if (authorizedUser) {
    if (isMeetingCancelled) {
      return 'already-cancelled';
    }
    if (isMeetingCompleted) {
      return 'already-completed';
    }
    if (isMeetingDeclined) {
      return 'already-declined';
    }
    if (isMeetingStarted) {
      return 'already-started';
    }
    return 'accept';
  }
  return 'not-participant';
};
