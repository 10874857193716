import { filter, find, first, get } from 'lodash';
import { produce } from 'immer';
import { catchError } from '@utils/sentry';
import { candidateAsyncPermissionAllowed } from '@utils/mixpanel/mixpanelActions';

export const getConstraints = (
  videoSourceDevice: MediaDeviceInfo | undefined,
  audioSourceDevice: MediaDeviceInfo | undefined
) => ({
  video: {
    width: 320,
    height: 240,
    aspectRatio: 1.3333333333333333,
    frameRate: { ideal: 30 }, // Frame rate
    facingMode: 'user', // Use 'environment' for back camera
    deviceId: videoSourceDevice ? { exact: get(videoSourceDevice, 'deviceId') } : undefined,
  },
  audio: {
    echoCancellation: true, // Enable echo cancellation
    noiseSuppression: true, // Enable noise suppression
    autoGainControl: true, // Enable auto gain control
    deviceId: audioSourceDevice ? { exact: get(audioSourceDevice, 'deviceId') } : undefined,
  },
});

export const getStream = async (constraints: MediaStreamConstraints) => {
  return await navigator.mediaDevices.getUserMedia(constraints);
};

export const getStreamTracks = (stream: MediaStream) => {
  const videoTrack = first(stream.getVideoTracks());
  const audioTrack = first(stream.getAudioTracks());
  return { videoTrack, audioTrack };
};

export const getDevicePermission = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  candidateAsyncPermissionAllowed({ 'Permission Status': 'allowed' });
  const devices = await navigator.mediaDevices.enumerateDevices();
  if (devices.length) {
    const videoDevices = filter(devices, (device) => device.kind === 'videoinput');
    const audioDevices = filter(devices, (device) => device.kind === 'audioinput');
    const { videoTrack, audioTrack } = getStreamTracks(stream);
    const selectedVideoDevice = find(videoDevices, (device) => device.deviceId === videoTrack?.getSettings()?.deviceId);
    const selectedAudioDevice = find(audioDevices, (device) => device.deviceId === audioTrack?.getSettings()?.deviceId);
    stopStream(stream);
    return { videoDevices, audioDevices, selectedVideoDevice, selectedAudioDevice };
  } else {
    candidateAsyncPermissionAllowed({ 'Permission Status': 'denied' });
    return Promise.reject(new Error('getDevicePermission: No media devices found.'));
  }
};

export const stopStream = (stream: MediaStream | undefined) => {
  if (stream && stream.active) {
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  }
};

export const getSupportedMimeType = () => {
  let mimeTypeSupported;
  if (typeof window.MediaRecorder == 'function' && typeof window.MediaRecorder.isTypeSupported == 'function') {
    if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
      mimeTypeSupported = 'video/webm;codecs=vp8,opus';
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      mimeTypeSupported = 'video/webm';
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      mimeTypeSupported = 'video/mp4';
    }
    return mimeTypeSupported;
  }
};

export interface MediaState {
  availableVideoInputDevices: MediaDeviceInfo[];
  availableAudioInputDevices: MediaDeviceInfo[];
  selectedVideoInputDevice: MediaDeviceInfo | undefined;
  selectedAudioInputDevice: MediaDeviceInfo | undefined;
  videoTrack: MediaStreamTrack | undefined;
  audioTrack: MediaStreamTrack | undefined;
  stream: MediaStream | undefined;
}

export type MediaStateAction =
  | {
      type: 'SET_MEDIA_CONTENTS';
      payload: {
        videoDevices: MediaDeviceInfo[];
        audioDevices: MediaDeviceInfo[];
        selectedVideoDevice: MediaDeviceInfo | undefined;
        selectedAudioDevice: MediaDeviceInfo | undefined;
        // videoTrack: MediaStreamTrack | undefined;
        // audioTrack: MediaStreamTrack | undefined;
      };
    }
  | { type: 'SET_SELECTED_AUDIO_INPUT_DEVICE'; payload: MediaDeviceInfo | undefined }
  | { type: 'SET_SELECTED_VIDEO_INPUT_DEVICE'; payload: MediaDeviceInfo | undefined }
  | { type: 'RESET' }
  | {
      type: 'SET_STREAM';
      payload: {
        stream: MediaStream | undefined;
        videoTrack: MediaStreamTrack | undefined;
        audioTrack: MediaStreamTrack | undefined;
      };
    };

export const initialMediaState = {
  availableVideoInputDevices: [],
  availableAudioInputDevices: [],
  selectedVideoInputDevice: undefined,
  selectedAudioInputDevice: undefined,
  videoTrack: undefined,
  audioTrack: undefined,
  stream: undefined,
};

export const mediaStateReducer = (mediaState: MediaState, action: MediaStateAction) => {
  return produce(mediaState, (draftState) => {
    switch (action.type) {
      case 'SET_MEDIA_CONTENTS':
        draftState.availableVideoInputDevices = action.payload.videoDevices;
        draftState.availableAudioInputDevices = action.payload.audioDevices;
        draftState.selectedVideoInputDevice = action.payload.selectedVideoDevice;
        draftState.selectedAudioInputDevice = action.payload.selectedAudioDevice;
        break;
      case 'SET_SELECTED_AUDIO_INPUT_DEVICE':
        draftState.selectedAudioInputDevice = action.payload;
        break;
      case 'SET_SELECTED_VIDEO_INPUT_DEVICE':
        draftState.selectedVideoInputDevice = action.payload;
        break;
      case 'SET_STREAM':
        draftState.stream = action.payload.stream;
        draftState.videoTrack = action.payload.videoTrack;
        draftState.audioTrack = action.payload.audioTrack;
        break;
      default:
        return mediaState;
    }
  });
};

export const getIdRecordingSupported = () => {
  if (!window.MediaRecorder) {
    // Todo: Show a message to the user for browsers not supporting MediaRecorder
    catchError({
      title: 'Recording is not supported',
      error: new Error(
        'Recording is not supported in your browser. Please try using a modern browser like Chrome or Firefox.'
      ),
      skipToast: true,
    });

    return false;
  }
  if (!getSupportedMimeType()) {
    // Todo: Show a message to the user for mimeTypes not supported
    catchError({
      title: 'No suitable MIME type',
      error: new Error('No suitable MIME type found for recording.'),
    });
    return false;
  }
  return true;
};
