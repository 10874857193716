// Generic utility to check the existence of a key and validate its type

/**
 * A type guard that checks if the given object contains a specific key
 * and validates whether the value associated with that key is an array or not.
 *
 * @param data - The data to check. It can be of any type (unknown).
 * @param key - The key to check within the object. It must be a key of the object type T.
 * @param isArray - A flag indicating whether the value of the key should be an array (true)
 *                  or not an array (false).
 *
 * @returns true if the data contains the key and the value is of the expected type (array or non-array).
 *          false otherwise.
 *
 * T: The type of the object you expect the data to conform to.
 * K: A specific key of the object T.
 * key: K: A key from the object T. The function will check if this key exists in the data object.
 * data is T: The function is a type guard, which means it helps TypeScript narrow the type of data to T if the check passes (i.e., if the return value is true).
 * It returns a boolean (true or false), indicating whether the object is valid according to the checks
 */

export const isValidDataKey = <T, K extends keyof T>(data: unknown, key: K, isArray: boolean): data is T => {
  if (typeof data === 'object' && data !== null && key in data) {
    //  Retrieve the value of the key from the object and check its type
    const value = (data as Record<K, unknown>)[key];

    return isArray ? Array.isArray(value) : !Array.isArray(value);
  }
  return false;
};
