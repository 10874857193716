import { gql } from '@apollo/client';

export const CANDIDATE_CREATE_PROFILE_MUTATION = gql`
  mutation candidateCreateProfile($external_id: String, $candidate_email: String) {
    ca_create_profile(external_id: $external_id, candidate_email: $candidate_email) {
      data
      success
      error_message
    }
  }
`;

export const CREATE_FACE_AUTH_REQUEST_MUTATION = gql`
  mutation createFaceAuthRequest($file: String!, $file_name: String!, $candidate_id: Int, $description: String, $redirect_uri: String) {
    ca_create_auth_request(file: $file, file_name: $file_name, candidate_id: $candidate_id, description: $description, redirect_uri: $redirect_uri) {
      data
      error_message
      success
    }
  }
`;