import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SagaCallback } from '@store/types';
import {
  CancelDriveOccurrenceGuestInputProps,
  CancelDriveOccurrenceGuestProps,
  ConfirmDriveSlotInputProps,
  DriveOccurrenceDetailsProps,
  DriveState,
  FetchDriveOccurrenceDetailsInputProps,
  LobbyHeartBeatProps,
  SchHeartBeatResponse,
  SchUpdateDriveMemberStatusResponse,
  UpdateDriveMemberStatusInputProps,
} from './types';

export const initialState: DriveState = {
  driveData: null,
  loading: false,
  error: null,
  confirmLoading: false,
  confirmSuccess: false,
  driveOccurrence: null,
  lobbyHeartbeatData: null,
  lobbyDriveScheduleData: null,
};

export const driveSlice = createSlice({
  name: 'driveDetails',
  initialState,
  reducers: {
    fetchDriveDetails: (state, _action: PayloadAction<{ driveOccurrenceGuestId: number; callback?: SagaCallback }>) => {
      state.loading = true;
    },
    driveDetailsSuccess: (state, action) => {
      state.loading = false;
      state.driveData = action.payload;
    },

    confirmDriveSlotRequest: (state, _action: PayloadAction<ConfirmDriveSlotInputProps>) => {
      state.confirmLoading = true;
    },
    confirmDriveSlotSuccess: (state) => {
      state.confirmLoading = false;
      state.confirmSuccess = true;
    },
    confirmDriveSlotFailure: (state, action: PayloadAction<string>) => {
      state.confirmLoading = false;
      state.confirmError = action.payload;
    },

    fetchDriveOccurrenceDetails: (state, _action: PayloadAction<FetchDriveOccurrenceDetailsInputProps>) => {
      state.loading = true;
    },
    driveOccurrenceDetailsSuccess: (state, action: PayloadAction<DriveOccurrenceDetailsProps[]>) => {
      state.loading = false;
      state.driveOccurrence = action.payload;
      state.error = null;
    },

    cancelDriveOccurrenceGuestRequest: (state, _action: PayloadAction<CancelDriveOccurrenceGuestInputProps>) => {
      state.loading = true;
    },
    cancelDriveOccurrenceGuestSuccess: (state, _action: PayloadAction<CancelDriveOccurrenceGuestProps>) => {
      state.loading = false;
    },

    registerCandidateToDriveRequest: (_state, _action: PayloadAction<{ callback?: SagaCallback }>) => {},
    registerCandidateToDriveSuccess: (_state, _action: PayloadAction<{ orderId: string | number }>) => {},

    subscribeLobbyDriveScheduleRequest: (state, _action: PayloadAction<FetchDriveOccurrenceDetailsInputProps>) => {
      state.loading = true;
    },
    subscribeLobbyDriveScheduleDetailsSuccess: (state, action) => {
      state.loading = false;
      state.lobbyDriveScheduleData = action.payload;
    },
    // subscribeLobbyDriveScheduleDetailsSuccess: (state, action: PayloadAction<SubscriptionLoobyDriveResponse>) => {
    //   state.loading = false;
    //   state.lobbyDriveScheduleData = action.payload.data.sch_drive_lobby;
    // },
    unsubscribeLobbyDriveSchedule: (state) => {
      state.loading = false;
      state.lobbyDriveScheduleData = null; // Set back to null when unsubscribed
    },

    updateDriveMemberStatusRequest: (state, _action: PayloadAction<UpdateDriveMemberStatusInputProps>) => {
      state.loading = true;
    },
    updateDriveMemberStatusSuccess: (state, _action: PayloadAction<SchUpdateDriveMemberStatusResponse>) => {
      state.loading = false;
    },

    heartBeatStatusRequest: (state, _action: PayloadAction<LobbyHeartBeatProps>) => {
      state.loading = true;
    },
    hearBeatStatusSuccess: (state, action: PayloadAction<SchHeartBeatResponse>) => {
      state.loading = false;
      state.lobbyHeartbeatData = action.payload;
    },
    stopLobbyDriveHeartBeat: (state) => {
      state.loading = false;
    },
  },
});

export const {
  fetchDriveDetails,
  driveDetailsSuccess,
  confirmDriveSlotRequest,
  confirmDriveSlotSuccess,
  confirmDriveSlotFailure,
  fetchDriveOccurrenceDetails,
  driveOccurrenceDetailsSuccess,
  cancelDriveOccurrenceGuestRequest,
  cancelDriveOccurrenceGuestSuccess,
  registerCandidateToDriveRequest,
  registerCandidateToDriveSuccess,
  subscribeLobbyDriveScheduleRequest,
  subscribeLobbyDriveScheduleDetailsSuccess,
  unsubscribeLobbyDriveSchedule,
  updateDriveMemberStatusRequest,
  updateDriveMemberStatusSuccess,
  heartBeatStatusRequest,
  hearBeatStatusSuccess,
  stopLobbyDriveHeartBeat,
} = driveSlice.actions;

export default driveSlice.reducer;
