import { call, fork, takeLatest, select, put, debounce, delay } from '@redux-saga/core/effects';
import { filter, get } from 'lodash';
import {
  fetchNextQuestionById,
  fetchQuestionsList,
  fetchTcfMeasure,
  saveAnswer,
  saveAppState,
  getAppState,
  startAssessment,
  startSection,
  stopSection,
  initialize,
  storeAssessment,
  storeSection,
  storeNextQuestion,
  storeAnswer,
  storeStopSection,
  fetchTestInstance,
  startAssessmentError,
  storeTcfMeasurement,
  resetSectionState,
  submitSection,
  storeAppState,
  storeQuestionList,
  saveBulkAnswer,
  storeBulkAnswer,
  storeStepDetails,
} from '@containers/Assessment/slice';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import {
  GET_NEXT_QUESTION_BY_ID,
  SAVE_ANSWERS,
  START_ASSESSMENT,
  START_SECTION,
  STOP_SECTION,
  GET_TCF_MEASUREMENT,
  CREATE_TEST_INSTANCE,
  CANX_GET_APP_STATE,
  CANX_UPSERT_APP_STATE,
  GET_ALL_QUESTION,
  GET_STEP_DETAILS,
} from '@containers/Assessment/queries';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  GetNextQuestionOutput,
  InstanceOutput,
  ProcessedStartAssessmentResponse,
  ProcessedStartSectionResponse,
  QuestionOutput,
  SaveAnswerPayload,
  SaveAnswersOutput,
  SectionOutput,
  StartAssessmentOutput,
  StartSectionOutput,
  StopSectionOutput,
  TcfMeasureResponse,
  CreateTestInstanceResponse,
  TcfMeasurement,
  SaveAppStatePayload,
  AppStateData,
  GetAppStateResponse,
  UpsertAppStateOutput,
  GetAllQuestionOutput,
  AnswersOutput,
  ParseBulkSavedAnswerResponse,
  CANX_GET_STEP_DETAILS,
} from '@containers/Assessment/types';
import { selectQueryParams } from '@containers/Auth/selectors';
import { QueryParams } from '@containers/Auth/types';
import {
  getNextQuestionId,
  getOverallSections,
  parseBulkSavedAnswer,
  parseSubmittedAnswer,
  processStartAssessmentResponse,
  processStartSectionResponse,
} from '@containers/Assessment/helpers';
import {
  selectAnswers,
  selectAppState,
  selectAllQuestion,
  selectCurrentQuestion,
  selectNextQuestionId,
  selectQuestionIds,
  selectStartSectionResponse,
  selectTestInstanceId,
} from '@containers/Assessment/selectors';
import { getCurrentDate } from '@utils/dateHelpers';
import { SagaCallback } from '@store/types';
import fetchData from '@utils/fetchData';
import { storeQueryParams } from '@containers/Auth/slice';
import { find } from 'lodash';
import { bulkAnswerSubmitted } from '@utils/mixpanel/mixpanelActions';
import { BulkAnswerSubmitEventProps } from '@utils/mixpanel/types';
import { stopHeartBeat } from '@containers/App/types';

export function* initializeWorker(): Generator {
  try {
    const testInstanceId = (yield select(selectTestInstanceId)) as number;
    if (testInstanceId) {
      yield put(startAssessment({ instance_id: testInstanceId }));
    } else {
      throw Error('No test_instance_id found in queryParams');
    }
  } catch (error) {
    yield call(catchError, {
      title: 'Assessment initialize Worker',
      error: error as Error,
    });
  }
}

export function* fetchStepDetailsWorker(): Generator {
  try {
    const queryParams = (yield select(selectQueryParams)) as QueryParams;
    if (queryParams?.step_id) {
      const response = (yield call(postData, {
        queryString: GET_STEP_DETAILS,
        payload: {
          step_id: queryParams.step_id,
        },
        spreadPayload: true,
      })) as CANX_GET_STEP_DETAILS;
      if (response?.data?.canx_get_step_details?.id) {
        yield put(storeStepDetails(response?.data?.canx_get_step_details));
      }
    }
  } catch (error) {
    yield call(catchError, {
      title: 'Fetching step details failed.',
      error: error as Error,
    });
  }
}

function* startAssessmentWorker(): Generator {
  try {
    const testInstanceId = (yield select(selectTestInstanceId)) as number;
    const response = (yield call(postData, {
      queryString: START_ASSESSMENT,
      payload: {
        instance_id: testInstanceId,
      },
    })) as StartAssessmentOutput;
    const result = response?.data?.canx_start_assessment[0];
    if (result?.success) {
      const processedData = (yield call(
        processStartAssessmentResponse,
        result.data as InstanceOutput
      )) as ProcessedStartAssessmentResponse;
      yield put(storeAssessment(processedData));
    } else {
      const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : (result?.error_message as string);
      throw Error(`Failed to start assessment: ${err}`);
    }
  } catch (error) {
    const err = error as Error;
    yield call(catchError, {
      title: 'startAssessmentWorker',
      error: err,
    });
    yield put(startAssessmentError({ error: err && (err?.message as string) }));
  }
}

export function* startSectionWorker({ payload }: PayloadAction<{ callback: SagaCallback }>): Generator {
  try {
    const testInstanceId = (yield select(selectTestInstanceId)) as number;
    const response = (yield call(postData, {
      queryString: START_SECTION,
      payload: { instance_id: testInstanceId },
    })) as StartSectionOutput;

    const result = response?.data?.canx_start_next_section[0];
    if (result?.success) {
      if (result.data?.id) {
        yield call(getAppStateWorker, {
          payload: {
            section_id: result.data?.id as number,
          },
          type: getAppState.type,
        });
      }
      const appState = (yield select(selectAppState)) as AppStateData;
      const processedData = (yield call(
        processStartSectionResponse,
        result.data as SectionOutput,
        appState
      )) as ProcessedStartSectionResponse;
      if (!processedData.assessmentSection) {
        yield call(payload.callback.onSuccess, {
          redirectTo: '/assessment/result',
        });
      } else if (!processedData.next_question_id) {
        yield put(storeSection(processedData));
        yield call(payload.callback.onSuccess, {
          redirectTo: '/assessment/submit',
        });
      } else {
        yield put(storeSection(processedData));
        yield call(fetchQuestionsListWorker);
        if (payload.callback.onSuccess) {
          yield call(payload.callback.onSuccess);
        }
      }
    } else {
      const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : (result?.error_message as string);
      throw Error(`Failed to start section: ${err}`);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'startSectionWorker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* getNextSectionWorker({ payload }: PayloadAction<{ callback: SagaCallback }>): Generator {
  try {
    const testInstanceId = (yield select(selectTestInstanceId)) as number;
    const response = (yield call(postData, {
      queryString: START_SECTION,
      payload: { instance_id: testInstanceId },
    })) as StartSectionOutput;

    const result = response?.data?.canx_start_next_section[0];
    if (result?.success) {
      const processedData = (yield call(
        processStartSectionResponse,
        result.data as SectionOutput
      )) as ProcessedStartSectionResponse;
      if (processedData.assessmentSection) {
        yield put(resetSectionState());
        yield call(payload.callback.onSuccess, {
          redirectTo: '/assessment/invite',
        });
      } else {
        if (window.self !== window.top) {
          window.parent.postMessage({ exit: true, discardExitConfirmation: true }, '*');
        } else {
          yield call(payload.callback.onSuccess, {
            redirectTo: '/assessment/feedback',
          });
        }
      }
    } else {
      const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : (result?.error_message as string);
      throw Error(`Failed to start section: ${err}`);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'startSectionWorker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* fetchNextQuestionByIdWorker({ payload }: PayloadAction<{ callback: SagaCallback }>): Generator {
  try {
    const nextQuestionId = (yield select(selectNextQuestionId)) as number | null;
    if (!nextQuestionId) {
      yield put({ type: stopHeartBeat });
      if (payload.callback.onSuccess) {
        yield call(payload.callback.onSuccess, { redirectTo: '/assessment/submit' });
      }
    } else {
      let result;
      const questions = (yield select(selectAllQuestion)) as QuestionOutput[] | null;
      if (questions?.length) {
        const question = nextQuestionId ? find(questions, { id: nextQuestionId as number }) : null;
        if (question) {
          result = {
            success: true,
            data: [question],
          };
        } else {
          result = {
            error_message: 'Question not found in question list',
          };
        }
      } else {
        const startSectionResponse = (yield select(selectStartSectionResponse)) as SectionOutput;
        const response = (yield call(postData, {
          queryString: GET_NEXT_QUESTION_BY_ID,
          payload: {
            question_id: nextQuestionId,
            section_id: startSectionResponse?.id,
          },
          spreadPayload: true,
        })) as GetNextQuestionOutput;

        result = response?.data?.ae_get_next_question;
      }
      if (result?.success) {
        const questionIds = (yield select(selectQuestionIds)) as number[];
        const question = result?.data?.[0];
        const nextQuestionId = getNextQuestionId(questionIds, question?.id as number);
        yield put(storeNextQuestion({ data: result.data?.[0] as QuestionOutput, next_question_id: nextQuestionId }));
        if (payload.callback.onSuccess) {
          yield call(payload.callback.onSuccess);
        }
      } else {
        const err = Array.isArray(result?.error_message)
          ? result?.error_message?.[0]
          : (result?.error_message as string);
        throw Error(`Failed to start section: ${err}`);
      }
    }
  } catch (error) {
    yield call(catchError, {
      title: 'fetchNextQuestionByIdWorker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* fetchQuestionsListWorker(): Generator {
  try {
    const testInstanceId = (yield select(selectTestInstanceId)) as number;
    const startSectionResponse = (yield select(selectStartSectionResponse)) as SectionOutput;
    const response = (yield call(postData, {
      queryString: GET_ALL_QUESTION,
      payload: {
        instance_id: testInstanceId,
        section_id: startSectionResponse?.id,
      },
      spreadPayload: true,
    })) as GetAllQuestionOutput;

    if (response) {
      yield put(storeQuestionList({ data: response?.data?.ae_get_question_list?.data as QuestionOutput[] }));
    }
  } catch (error) {
    yield call(catchError, {
      title: 'fetchQuestionsListWorker',
      error: error as Error,
    });
  }
}

export function* saveAnswerWorker({
  payload,
}: PayloadAction<{
  data: SaveAnswerPayload;
  callback: SagaCallback;
}>): Generator {
  try {
    const currentQuestion = (yield select(selectCurrentQuestion)) as QuestionOutput;
    const startSectionResponse = (yield select(selectStartSectionResponse)) as SectionOutput;
    const questions = (yield select(selectAllQuestion)) as QuestionOutput[] | null;
    let result;
    if (questions) {
      result = parseSubmittedAnswer(payload, currentQuestion, startSectionResponse);
    } else {
      const response = (yield call(postData, {
        queryString: SAVE_ANSWERS,
        payload: {
          question_id: currentQuestion.id,
          section_id: startSectionResponse.id,
          answered_at: getCurrentDate()?.toUTC().toISO(),
          choice: payload.data.choice,
        },
      })) as SaveAnswersOutput;
      result = response?.data?.ae_save_answer[0];
    }
    if (result?.success) {
      yield put(storeAnswer(result.data as AnswersOutput));
      if (!(typeof result?.data?.is_correct_choice === 'boolean')) {
        yield put(fetchNextQuestionById({ callback: payload.callback }));
      } else {
        if (payload.callback.onSuccess) {
          yield call(payload.callback.onSuccess);
        }
      }
    } else {
      const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : (result?.error_message as string);
      throw Error(`Failed to save answer:  ${err}`);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'saveAnswerWorker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* saveBulkAnswerWorker({ payload }: PayloadAction<{ callback: SagaCallback }>): Generator {
  try {
    const allAnswers = (yield select(selectAnswers)) as AnswersOutput[];
    const answers = (yield call(
      filter,
      allAnswers,
      (obj: AnswersOutput) => obj.id === 'has_to_save'
    )) as AnswersOutput[];
    const eventOption: BulkAnswerSubmitEventProps = {
      Type: 'Manual',
    };
    bulkAnswerSubmitted(eventOption);
    if (answers.length) {
      const formatedAnswerObject = answers.map((item) => ({
        question_id: item.question_id,
        section_id: item.section_id,
        answered_at: item.answered_at,
        choice: item.choice,
      }));
      const response = (yield call(postData, {
        queryString: SAVE_ANSWERS,
        payload: formatedAnswerObject,
      })) as SaveAnswersOutput;
      const parseBulkSavedAnswerResponse = (yield call(
        parseBulkSavedAnswer,
        response,
        allAnswers
      )) as ParseBulkSavedAnswerResponse;
      if (payload.callback.onSuccess && parseBulkSavedAnswerResponse) {
        yield put(storeBulkAnswer(parseBulkSavedAnswerResponse.formattedAnswers));
        yield call(payload.callback.onSuccess, parseBulkSavedAnswerResponse);
      }
    } else {
      yield put(stopSection({ callback: payload.callback }));
    }
  } catch (error) {
    yield call(catchError, {
      title: 'saveBulkAnswerWorker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* stopSectionWorker({
  payload,
}: PayloadAction<{
  callback: SagaCallback;
  async: boolean;
}>): Generator {
  try {
    const startSectionResponse = (yield select(selectStartSectionResponse)) as SectionOutput;
    const response = (yield call(postData, {
      queryString: STOP_SECTION,
      payload: {
        section_id: startSectionResponse.id,
      },
    })) as StopSectionOutput;

    const result = response?.data?.ae_stop_section[0];
    if (result?.success) {
      yield put(storeStopSection({ startSectionResponse: result.data }));
      if (payload.callback.onSuccess) {
        if (payload?.async) {
          yield call(payload.callback.onSuccess, { redirectTo: '/assessment/feedback' });
        } else {
          yield call(payload.callback.onSuccess, { redirectTo: '/assessment/result' });
        }
      }
    } else {
      const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : (result?.error_message as string);
      throw Error(`Failed to stop section:  ${err}`);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'stopSectionWorker',
      error: error as Error,
    });
  }
}

export function* fetchTcfMeasureWorker({
  payload: { callback },
}: PayloadAction<{
  callback: SagaCallback;
}>): Generator {
  try {
    const testInstanceId = (yield select(selectTestInstanceId)) as number;
    const response = (yield call(fetchData, {
      queryString: GET_TCF_MEASUREMENT,
      queryKey: 'ae_get_instance_score',
      queryVariables: { test_instance_id: testInstanceId },
      forceRefresh: true,
      context: {},
    })) as TcfMeasureResponse[];
    const result = response[0];
    const overallData = (yield call(getOverallSections, result.data)) as TcfMeasurement[];
    if (!overallData.length) {
      yield delay(5000);
      yield put(fetchTcfMeasure({ callback }));
    } else if (callback.onSuccess) {
      yield put(storeTcfMeasurement(overallData));
      yield call(callback.onSuccess);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'fetchTcfMeasureWorker',
      error: error as Error,
    });
  }
}

export function* saveAppStateWorker({
  payload,
}: PayloadAction<{
  data: SaveAppStatePayload;
  callback?: SagaCallback;
}>): Generator {
  try {
    const { id } = (yield select(selectStartSectionResponse)) as SectionOutput;
    const answers = (yield select(selectAnswers)) as AnswersOutput[];
    const currentQuestion = (yield select(selectCurrentQuestion)) as QuestionOutput;
    const nextQuestionId = yield select(selectNextQuestionId);
    const formatedPayload = {
      section_id: id,
      state: [
        {
          remaining_time: payload?.data?.remaining_time,
          answers: answers,
          currentQuestionId: currentQuestion?.id,
          nextQuestionId: nextQuestionId,
        },
      ],
    };
    const response = (yield call(postData, {
      queryString: CANX_UPSERT_APP_STATE,
      payload: formatedPayload,
    })) as UpsertAppStateOutput;
    const result = response?.data?.canx_upsert_app_state[0];
    if (result?.success) {
      yield put(storeAppState(result?.data));
    } else {
      yield call(catchError, {
        title: 'saveAppStateWorker',
        error: Error('Failed to save the appState to server'),
        extraScope: { key: 'appState', value: JSON.stringify(formatedPayload) },
        skipToast: true,
      });
    }
  } catch (error) {
    yield call(catchError, {
      title: 'saveAppStateWorker',
      error: error as Error,
    });
  }
}

export function* createTestInstanceWorker({
  payload,
}: PayloadAction<{
  callback: SagaCallback;
}>): Generator {
  try {
    const testInstanceId = (yield select(selectTestInstanceId)) as number;
    if (testInstanceId) {
      const response = (yield call(postData, {
        queryString: CREATE_TEST_INSTANCE,
        payload: {
          test_instance_id: testInstanceId,
          reattempt: true,
        },
        spreadPayload: true,
      })) as CreateTestInstanceResponse;
      const result = response?.data?.canx_create_test_instance[0];
      if (result?.success) {
        const queryParams = (yield select(selectQueryParams)) as QueryParams;
        const test_instance_id = result?.data?.test_instance_id;
        if (test_instance_id) {
          yield put(storeQueryParams({ ...queryParams, test_instance_id: String(test_instance_id) }));
          yield put(initialize());
          if (payload.callback.onSuccess) {
            yield call(payload.callback.onSuccess);
          }
        } else {
          throw Error('createTestInstanceWorker: Test instance id not found in the reattempt response');
        }
      } else {
        const err = Array.isArray(result?.error_message)
          ? result?.error_message?.[0]
          : (result?.error_message as string);
        throw Error(`Failed to createTestInstance: ${err}`);
      }
    } else {
      throw Error('createTestInstance: No test instance id found');
    }
  } catch (error) {
    yield call(catchError, {
      title: 'createTestInstance',
      error: error as Error,
    });
    if (payload?.callback?.onError) yield call(payload.callback.onError, error as Error);
  }
}

export function* getAppStateWorker({
  payload,
}: PayloadAction<{
  section_id: SectionOutput['id'];
  callback?: SagaCallback;
}>): Generator {
  try {
    if (payload?.section_id) {
      const response = (yield call(fetchData, {
        queryString: CANX_GET_APP_STATE,
        queryVariables: {
          section_id: Number(payload?.section_id),
        },
      })) as GetAppStateResponse;
      const result = get(response, 'canx_get_app_state.0', null);
      if (result?.section_id) {
        yield put(storeAppState(result));
        if (payload?.callback?.onSuccess) {
          yield call(payload?.callback.onSuccess);
        }
      }
    } else {
      throw Error('No Section id found');
    }
  } catch (error) {
    yield call(catchError, {
      title: 'getAppStateInstance',
      error: error as Error,
    });
  }
}

export function* initializeWatcher() {
  yield debounce(1000, initialize.type, initializeWorker);
}

export function* startAssessmentWatcher() {
  yield takeLatest(startAssessment.type, startAssessmentWorker);
}

export function* startSectionWatcher() {
  yield debounce(1000, startSection.type, startSectionWorker);
}

export function* getNextSectionWatcher() {
  yield takeLatest(submitSection.type, getNextSectionWorker);
}

export function* fetchNextQuestionByIdWatcher() {
  yield takeLatest(fetchNextQuestionById.type, fetchNextQuestionByIdWorker);
}

export function* fetchQuestionsListWatcher() {
  yield takeLatest(fetchQuestionsList.type, fetchQuestionsListWorker);
}

export function* saveAnswerWatcher() {
  yield takeLatest(saveAnswer.type, saveAnswerWorker);
}

export function* stopSectionWatcher() {
  yield takeLatest(stopSection.type, stopSectionWorker);
}

export function* fetchTcfMeasureWatcher() {
  yield debounce(1000, fetchTcfMeasure.type, fetchTcfMeasureWorker);
}

export function* saveAppStateWatcher() {
  yield debounce(2000, saveAppState.type, saveAppStateWorker);
}

export function* createTestInstanceWatcher() {
  yield takeLatest(fetchTestInstance.type, createTestInstanceWorker);
}

export function* getAppStateWatcher() {
  yield takeLatest(getAppState.type, getAppStateWorker);
}

export function* saveBulkAnswerWatcher() {
  yield debounce(1000, saveBulkAnswer.type, saveBulkAnswerWorker);
}

export function* assessmentRootSaga() {
  yield fork(initializeWatcher);
  yield fork(startAssessmentWatcher);
  yield fork(startSectionWatcher);
  yield fork(fetchNextQuestionByIdWatcher);
  yield fork(fetchQuestionsListWatcher);
  yield fork(saveAnswerWatcher);
  yield fork(stopSectionWatcher);
  yield fork(fetchTcfMeasureWatcher);
  yield fork(saveAppStateWatcher);
  yield fork(createTestInstanceWatcher);
  yield fork(getNextSectionWatcher);
  yield fork(getAppStateWatcher);
  yield fork(saveBulkAnswerWatcher);
}
