import { GrowthBook } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

interface GrowthBookResponse {
  success?: boolean;
  error?: {
    message: string;
  };
}

export const growthBook = new GrowthBook({
  apiHost: process.env.GROWTHBOOK_API_HOST,
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== 'production',
  subscribeToChanges: true,
  trackingCallback(experiment, result) {
    Mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    });
  },
});

let count = 0;

export const initializeGrowthBook = async (): Promise<null> => {
  try {
    const resp: GrowthBookResponse = await growthBook.init({
      streaming: true,
      cacheSettings: {
        backgroundSync: true,
        cacheKey: 'gbFeaturesCache',
        staleTTL: 1000 * 60,
        maxAge: 0,
        maxEntries: 1,
        disableIdleStreams: false,
        idleStreamInterval: 20000,
        disableCache: true,
      },
    });

    if (!resp?.success) {
      throw new Error(resp?.error?.message);
    }
  } catch (e) {
    if (count < 3) {
      count += 1;
      return initializeGrowthBook();
    }

    const errorMessage = e instanceof Error ? e.message : String(e);
    const error = new Error(`Failed to initialize GrowthBook. ${errorMessage}`);
    Sentry.captureException(error);
    throw error;
  }

  return null;
};
