import { put, call, takeLatest, fork, select } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchMeeting, storeMeeting } from './slice';
import { GetMeetingData, MeetingParticipant, MeetingResponse, MeetingStates, ResponseData } from './types';
import { GET_MEETING } from './queries';
import fetchData from '@utils/fetchData';
import { selectMeeting } from './selectors';
import { selectUser } from '@containers/Auth/selectors';
import { findParticipant, getMeetingStates, meetingType } from './helpers';
import { User } from '@containers/Auth/types';

export function* fetchMeetingWorker({ payload }: PayloadAction<GetMeetingData>): Generator {
  try {
    const user = (yield select(selectUser)) as User;
    const cachedMeeting = (yield select(selectMeeting)) as MeetingResponse;
    const { meetingId, participantId } = payload;
    const id = meetingId || cachedMeeting?.id;
    const queryVariables = participantId
      ? { meeting_participants: { id: { _eq: participantId } } }
      : { id: { _eq: id } };

    const queryResponse = (yield call(fetchData, {
      queryString: GET_MEETING,
      queryVariables: { filter: queryVariables },
      queryKey: 'sch_meeting',
      forceRefresh: true,
      context: {},
    })) as ResponseData;

    const meeting = queryResponse?.[0];
    const userId = Number(user?.user_id);
    const participant = (yield call(findParticipant, meeting, userId, participantId)) as MeetingParticipant | null;

    if (meeting && participant) {
      const meetingStates = (yield call(getMeetingStates, meeting, participant)) as MeetingStates;
      const meetingConditionType = (yield call(meetingType, meeting, userId, participantId, participant)) as string;

      yield put(
        storeMeeting({
          meeting,
          participant,
          meetingConditionType,
          ...meetingStates,
        })
      );

      if (payload.callback?.onSuccess) {
        yield call(payload.callback.onSuccess, meeting);
      }
    } else {
      throw new Error('An error occurred while fetching the meetings data');
    }
  } catch (error) {
    yield call(catchError, { title: 'Meeting Worker', error: error as Error });

    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* fetchMeetingWatcher() {
  yield takeLatest(fetchMeeting.type, fetchMeetingWorker);
}

export function* schedulingRootSaga() {
  yield fork(fetchMeetingWatcher);
}
