import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export interface GrowthBookErrorProps {
  errorMessage: string;
}

const GrowthBookError = (props: GrowthBookErrorProps) => {
  const { errorMessage } = props;

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="mx-4 flex h-screen items-center justify-center">
      <div className="align-center relative flex flex-1 items-center justify-center space-y-6">
        <div className="flex flex-col items-center justify-center md:w-[431px]">
          <div className="mt-4 text-lg font-semibold text-gray-700">
            <FormattedMessage {...messages.loadingError} />
          </div>
          <div className="w-fit items-center justify-center text-base font-normal text-gray-700">{errorMessage}</div>
          <div className="w-fit items-center justify-center text-center text-base font-normal text-gray-700">
            <FormattedMessage {...messages.pageBodyMessage} />
          </div>
          <button
            className="mt-2 rounded-sm bg-blue-700 px-3 py-1.5 text-center text-base font-medium text-white shadow-md"
            onClick={handleRefresh}
          >
            <FormattedMessage {...messages.refresh} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GrowthBookError;
