import { call, debounce, fork, put, select } from 'redux-saga/effects';
import { first, get } from 'lodash';
import { selectQueryParams } from '@containers/Auth/selectors';
import { storeQueryParams } from '@containers/Auth/slice';
import { QueryParams } from '@containers/Auth/types';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { registerCandidateToDriveRequest, registerCandidateToDriveSuccess } from '../slice';
import { SagaCallback } from '@store/types';
import { RegisterCandidateToDriveResponse } from './types';
import { REGISTER_CANDIDATE_TO_DRIVE } from './queries';

export function* registerCandidateToDriveWorker({ payload }: { payload: { callback?: SagaCallback } }): Generator {
  try {
    const queryParams = (yield select(selectQueryParams)) as QueryParams;

    const queryResponse = (yield call(postData, {
      queryString: REGISTER_CANDIDATE_TO_DRIVE,
      payload: {
        drive_schedule_id: queryParams.driveScheduleId && Number(queryParams.driveScheduleId),
      },
    })) as RegisterCandidateToDriveResponse;

    const registerGuestResponse = queryResponse?.data?.sch_register_guest;

    if (registerGuestResponse?.success_count) {
      const schRegisterGuest = first(registerGuestResponse.data);
      const orderItem = first(schRegisterGuest?.drive_occurrence_guest?.pay_item?.order_items);
      const orderId = get(orderItem, 'order_id');
      const schDriveOccurrenceGuestItemId = Array.isArray(schRegisterGuest?.drive_occurrence_guest?.id)
        ? first(schRegisterGuest?.drive_occurrence_guest?.id)
        : schRegisterGuest?.drive_occurrence_guest?.id;

      yield put(
        storeQueryParams({
          ...queryParams,
          drive_occurrence_guest_id: String(schDriveOccurrenceGuestItemId),
          order_id: String(orderId),
        })
      );

      yield put(registerCandidateToDriveSuccess({ orderId: Number(orderId) }));

      if (payload?.callback?.onSuccess) {
        yield call(payload.callback.onSuccess, { orderId });
      }
    } else {
      throw new Error(
        JSON.stringify(registerGuestResponse) || 'An error occurred while registering the candidate to drive.'
      );
    }
  } catch (error) {
    yield call(catchError, { title: 'registerCandidateToDriveWorker', error: error as Error, skipToast: true });
    if (payload?.callback?.onError) {
      yield call(payload?.callback.onError, error as Error);
    }
  }
}

export function* registerCandidateToDriveWatcher() {
  yield debounce(500, registerCandidateToDriveRequest, registerCandidateToDriveWorker);
}

export function* rootDriveRegistrationSaga() {
  yield fork(registerCandidateToDriveWatcher);
}
