import { gql } from '@apollo/client';

export const PROPOSE_NEW_TIME = gql`
  mutation rsvpActionProposeNewTime($action: rsvp_status_enum!, $meeting_id: Int!, $proposed_new_time: timestamptz) {
    sch_rsvp_action(data: { action: $action, meeting_id: $meeting_id, proposed_new_time: $proposed_new_time }) {
      error_count
      success_count
      response {
        success
        error_message
      }
    }
  }
`;

export const GET_AUTH_USERS_BUSY = gql`
  query getAuthUsersEvents($payload: [wft_free_busy_input!]!) {
    wft_free_busy(data: $payload) {
      success
      error_message
      data {
        id
        user_id
        from
        to
        type
        status
      }
    }
  }
`;
