import React from 'react';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import router from './router';

function FallbackComponent() {
  return (
    <div
      className={'flex h-full w-full flex-1 items-center justify-center border border-blue-600'}
      data-testid="fallback-component"
    >
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
  );
}

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  );
};

export default App;
