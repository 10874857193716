import { put, call, fork, debounce, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { first } from 'lodash';
import { extractPaymentDetailsData, formatCouponData } from '@containers/Payment/payment.helper';
import { selectCouponDetails } from '@containers/Payment/selectors';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { localStorageGetItem, localStorageSetItem, PAYMENT_DETAILS } from '@utils/localStorageHelpers';
import { GET_ORDER_DETAILS_QUERY, CREATE_TRANSACTION_MUTATION, VALIDATE_COUPON_MUTATION } from './queries';
import {
  FetchOrderDetailsPayload,
  FormattedOrderData,
  CreateTransactionPayload,
  CreateTransactionResponseProps,
  ValidateCouponResponseProps,
  ValidateCouponInputProps,
  CouponDetails,
  OrderDataProps,
} from './types';
import {
  fetchOrderDetailsRequest,
  fetchOrderDetailsSuccess,
  createTransactionRequest,
  createTransactionSuccess,
  validateCouponRequest,
  validateCouponSuccess,
  validateCouponFailed,
} from './slice';
import fetchData from '@utils/fetchData';

export function* fetchOrderDetailsWorker(action: PayloadAction<FetchOrderDetailsPayload>): Generator {
  const { orderId, callback } = action.payload;

  try {
    const storedPaymentDetails = (yield call(localStorageGetItem, PAYMENT_DETAILS)) as string;
    const oId = orderId || Number(storedPaymentDetails);

    const paymentOrderResponse = (yield call(fetchData, {
      queryString: GET_ORDER_DETAILS_QUERY,
      queryVariables: { order_id: oId },
      queryKey: 'canx_get_order_details',
      forceRefresh: true,
    })) as OrderDataProps[];

    const orderDetails = paymentOrderResponse?.[0];

    if (orderDetails) {
      yield call(localStorageSetItem, PAYMENT_DETAILS, String(oId));
      const formattedData = (yield call(extractPaymentDetailsData, orderDetails)) as FormattedOrderData[];

      const data = first(formattedData) as FormattedOrderData;
      yield put(fetchOrderDetailsSuccess(data));

      if (callback?.onSuccess) {
        yield call(callback.onSuccess, formattedData);
      }
    } else {
      throw new Error('An error occurred while fetching order details.');
    }
  } catch (error) {
    yield call(catchError, { title: 'Fetch Order Details Worker', error: error as Error, skipToast: true });
    if (callback?.onError) {
      yield call(callback.onError, error as Error);
    }
  }
}

export function* createTransactionWorker(action: PayloadAction<CreateTransactionPayload>): Generator {
  const { callback } = action.payload;

  try {
    const couponDetails = (yield select(selectCouponDetails)) as CouponDetails;
    const storedPaymentDetails = (yield call(localStorageGetItem, PAYMENT_DETAILS)) as string;
    const orderId = Number(storedPaymentDetails);

    const createTransactionResponse = (yield call(postData, {
      queryString: CREATE_TRANSACTION_MUTATION,
      payload: { orderId, coupon_code: couponDetails.couponCode },
      spreadPayload: true,
    })) as CreateTransactionResponseProps;

    const transactionData = createTransactionResponse?.data?.pay_create_transaction;

    if (transactionData) {
      yield put(createTransactionSuccess());
      yield call(localStorageSetItem, PAYMENT_DETAILS, String(transactionData.order_id));
      if (callback?.onSuccess) {
        yield call(callback.onSuccess, transactionData);
      }
    } else {
      throw new Error('Transaction creation failed');
    }
  } catch (error) {
    yield call(catchError, { title: 'Create Transaction Worker', error: error as Error, skipToast: true });
    if (callback?.onError) {
      yield call(callback.onError, error as Error);
    }
  }
}

export function* validateCouponWorker(action: PayloadAction<ValidateCouponInputProps>): Generator {
  const { order_id, coupon_code, callback } = action.payload;

  try {
    const response = (yield call(postData, {
      queryString: VALIDATE_COUPON_MUTATION,
      payload: { orderId: order_id, couponCode: coupon_code },
      spreadPayload: true,
    })) as ValidateCouponResponseProps;

    const validateCouponResponse = response?.data?.pay_validate_coupon;

    if (validateCouponResponse) {
      const formatCouponRes = formatCouponData(validateCouponResponse);

      yield put(validateCouponSuccess(formatCouponRes));

      if (callback?.onSuccess) {
        yield call(callback.onSuccess, validateCouponResponse);
      }
    } else {
      throw new Error('Failed to validate coupon.');
    }
  } catch (error) {
    yield call(catchError, { title: 'Validate Coupon Worker', error: error as Error, skipToast: true });
    yield put(validateCouponFailed());
    if (callback?.onError) {
      yield call(callback.onError, error as Error);
    }
  }
}

export function* fetchOrderDetailsSagaWatcher() {
  yield debounce(1000, fetchOrderDetailsRequest.type, fetchOrderDetailsWorker);
}

export function* createTransactionSagaWatcher() {
  yield takeEvery(createTransactionRequest.type, createTransactionWorker);
}

export function* validateCouponSagaWatcher() {
  yield takeLatest(validateCouponRequest.type, validateCouponWorker);
}

export function* fetchOrderDetailsRootSaga() {
  yield fork(fetchOrderDetailsSagaWatcher);
  yield fork(createTransactionSagaWatcher);
  yield fork(validateCouponSagaWatcher);
}
