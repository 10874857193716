import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { createHeartbeatWorker } from '@containers/App/HeartBeatSaga';

import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
import { handleSagaError, handleSagaSuccess } from '@utils/sagaHelper';
import { catchException, processSagaError } from '@utils/catchException';
import { createApolloSubscriptionChannel, SubscriptionResponse } from '@utils/apolloEvent';

import { SagaCallback } from '@store/types';

import {
  CANCEL_DRIVE_OCCURRENCE_GUEST,
  CONFIRM_DRIVE_MEETING_SLOT,
  GET_DRIVE_OCCURRENCE,
  GET_DRIVE_OCCURRENCE_GUEST,
  GET_HEART_BEAT,
  SUBSCRIBE_DRIVE_LOBBY_MEETING,
  UPDATE_LOBBY_DRIVE_MEMBER_STATUS,
} from './queries';

import {
  cancelDriveOccurrenceGuestRequest,
  cancelDriveOccurrenceGuestSuccess,
  confirmDriveSlotRequest,
  confirmDriveSlotSuccess,
  driveDetailsSuccess,
  driveOccurrenceDetailsSuccess,
  fetchDriveDetails,
  fetchDriveOccurrenceDetails,
  heartBeatStatusRequest,
  subscribeLobbyDriveScheduleDetailsSuccess,
  subscribeLobbyDriveScheduleRequest,
  updateDriveMemberStatusRequest,
  updateDriveMemberStatusSuccess,
} from './slice';

import {
  CancelDriveOccurrenceGuestInputProps,
  CancelDriveOccurrenceGuestResponse,
  CanxGetDriveOccurrenceGuestResponse,
  ConfirmDriveSlotInputProps,
  ConfirmDriveSlotResponse,
  DriveOccurrenceDetailsProps,
  FetchDriveOccurrenceDetailsInputProps,
  LobbyHeartBeatProps,
  NormalizedDriveOccurrenceGuestResponse,
  SchUpdateDriveMemberStatusResponse,
  SubscriptionLoobyDriveResponse,
  UpdateDriveMemberStatusInputProps,
} from './types';

import { isSchDriveLobbyMeeting } from './validateSagaResponse';
import { createMockMeetingData } from './__mocks___/driveMeeting';
import { normalizeDriveOccurrenceGuestResponse } from './DriveHelper';

export function* fetchDriveOccurrenceGuestWorker(
  action: PayloadAction<{ driveOccurrenceGuestId: number; callback: SagaCallback }>
): Generator {
  const { driveOccurrenceGuestId, callback } = action.payload;

  try {
    const response = (yield call(fetchData, {
      queryString: GET_DRIVE_OCCURRENCE_GUEST,
      queryVariables: { driveOccurrenceGuestId },
      queryKey: 'canx_get_drive_occurrence_guest',
      forceRefresh: true,
    })) as CanxGetDriveOccurrenceGuestResponse;

    const normalizedDriveOccResponse = normalizeDriveOccurrenceGuestResponse(
      response
    ) as NormalizedDriveOccurrenceGuestResponse;

    if (normalizedDriveOccResponse) {
      yield put(driveDetailsSuccess(normalizedDriveOccResponse));
    } else {
      throw catchException.apiError('Normalized response is null for drive canx_get_drive_occurrence_guest', {
        driveOccurrenceGuestId,
      });
    }

    yield call(handleSagaSuccess, {
      callback: callback?.onSuccess,
      response: normalizedDriveOccResponse,
    });
  } catch (error) {
    const processedError = processSagaError(error, 'Error in Fetch Drive Occurrence Guest Worker');
    yield call(handleSagaError, {
      callback: callback?.onError,
      error: processedError,
      title: 'Fetch Drive Details Worker',
      skipToast: true,
    });
  }
}

export function* confirmDriveSlotWorker(action: PayloadAction<ConfirmDriveSlotInputProps>): Generator {
  const { driveSlotPayloadData, callback } = action.payload;
  const { drive_id, from, to } = driveSlotPayloadData;

  try {
    const response = (yield call(postData, {
      queryString: CONFIRM_DRIVE_MEETING_SLOT,
      payload: { drive_id, from, to },
      spreadPayload: true,
    })) as ConfirmDriveSlotResponse;

    const confirmDriveResponse = response?.data?.sch_confirm_drive_slot?.[0];

    if (confirmDriveResponse.success) {
      yield put(confirmDriveSlotSuccess());

      if (confirmDriveResponse?.data?.id) {
        // Dispatch action to trigger `fetchDriveOccurrenceGuestWorker`
        yield put({
          type: fetchDriveDetails.type,
          payload: {
            driveOccurrenceGuestId: confirmDriveResponse.data.id,
            callback: {
              onSuccess: function* () {
                yield call(handleSagaSuccess, {
                  callback: callback?.onSuccess,
                  response: confirmDriveResponse,
                });
              },
              onError: function* (error: Error) {
                yield call(handleSagaError, {
                  callback: callback?.onError,
                  error,
                  title: 'Fetch Drive Details Error',
                });
              },
            },
          },
        });
      } else {
        throw catchException.validationError('Missing driveOccurrenceGuestId in confirmDriveResponse', {
          response: confirmDriveResponse,
        });
      }
    } else {
      throw catchException.apiError(confirmDriveResponse.error_message || 'Failed to confirm drive slot.', {
        response: confirmDriveResponse,
      });
    }
  } catch (error) {
    const processedError = processSagaError(error, 'Error in Confirm Drive Slot Worker');
    yield call(handleSagaError, {
      callback: callback?.onError,
      error: processedError,
      title: processedError.message,
      skipToast: true,
    });
  }
}

export function* fetchDriveOccurrenceDetailsSagaWorker(
  action: PayloadAction<FetchDriveOccurrenceDetailsInputProps>
): Generator {
  const { driveScheduleId, callback } = action.payload;

  try {
    const response = (yield call(fetchData, {
      queryString: GET_DRIVE_OCCURRENCE,
      queryVariables: { drive_schedule_id: driveScheduleId },
      queryKey: 'sch_drive_occurrence',
      forceRefresh: true,
    })) as DriveOccurrenceDetailsProps[];

    if (response && response.length) {
      yield put(driveOccurrenceDetailsSuccess(response));
      yield call(handleSagaSuccess, { callback: callback?.onSuccess, response: response });
    } else {
      throw catchException.validationError('No source drive details found for the provided schedule ID.', {
        driveScheduleId,
      });
    }
  } catch (error) {
    yield call(handleSagaError, {
      callback: callback?.onError,
      error: processSagaError(error, 'An error occurred in fetchDriveOccurrenceDetailsSagaWorker'),
      title: 'Fetch Drive Details Error',
      skipToast: true,
    });
  }
}

export function* cancelDriveOccurrenceGuestSagaWorker(
  action: PayloadAction<CancelDriveOccurrenceGuestInputProps>
): Generator {
  const { driveOccurrenceGuestId, callback } = action.payload;

  try {
    const response = (yield call(postData, {
      queryString: CANCEL_DRIVE_OCCURRENCE_GUEST,
      payload: { driveOccurrenceGuestId },
      spreadPayload: true,
    })) as CancelDriveOccurrenceGuestResponse;

    if (response) {
      const successPayload = response?.data?.canx_cancel_drive_occurrence_guest;
      yield put(cancelDriveOccurrenceGuestSuccess(successPayload));
      yield call(handleSagaSuccess, { callback: callback?.onSuccess, response: successPayload });
    } else {
      throw catchException.validationError('error while cancel the drive with ID', {
        driveOccurrenceGuestId,
      });
    }
  } catch (error) {
    yield call(handleSagaError, {
      callback: callback?.onError,
      error: processSagaError(error, `Failed to cancel drive occurrence with ID ${driveOccurrenceGuestId}`),
      title: 'Cancel Drive Details',
      skipToast: true,
    });
  }
}

export function* updateDriveMemberStatusSagaWorker(
  action: PayloadAction<UpdateDriveMemberStatusInputProps>
): Generator {
  const { updateLobbyDriveMemberStatusPayload, callback } = action.payload;
  const { drive_schedule_id, drive_occurrence_id, status } = updateLobbyDriveMemberStatusPayload;

  try {
    const response = (yield call(postData, {
      queryString: UPDATE_LOBBY_DRIVE_MEMBER_STATUS,
      payload: { drive_schedule_id, drive_occurrence_id, status },
      spreadPayload: true,
    })) as SchUpdateDriveMemberStatusResponse;

    if (response) {
      // const extractRes = response?.data?.sch_update_drive_member_status?.[0];
      yield put(updateDriveMemberStatusSuccess(response));
      yield call(handleSagaSuccess, { callback: callback?.onSuccess, response: response });
    } else {
      throw catchException.validationError('Update lobby drive details found for the provided IDs/status.', {
        drive_schedule_id,
        drive_occurrence_id,
        status,
      });
    }
  } catch (error) {
    yield call(handleSagaError, {
      callback: callback?.onError,
      error: processSagaError(error, 'An error occurred in updateDriveMemberStatusSagaWorker'),
      title: 'Update Lobby Drive Member Status Error',
      skipToast: true,
    });
  }
}

export function* fetchHeartBeatLobbySagaWorker(action: PayloadAction<LobbyHeartBeatProps>) {
  const { driveOccurrenceId, callback } = action.payload;

  try {
    yield call(createHeartbeatWorker, {
      payload: { drive_occurrence_id: driveOccurrenceId },
      queryString: GET_HEART_BEAT,
      interval: 30 * 1000,
      callback,
    });
  } catch (error) {
    yield call(handleSagaError, {
      callback: callback?.onError,
      error: processSagaError(error, 'An error occurred in fetchHeartBeatLobbySagaWorker'),
      title: 'fetch heart beat drive details',
      skipToast: true,
    });
  }
}

export function* schDriveLobbyMeetingSubscriptionSagaWorker(
  action: PayloadAction<FetchDriveOccurrenceDetailsInputProps>
): Generator {
  const { driveScheduleId, callback } = action.payload;

  const query = SUBSCRIBE_DRIVE_LOBBY_MEETING;
  const variables = { drive_schedule_id: driveScheduleId };

  // Create the Apollo subscription channel to receive data and errors
  const channel = createApolloSubscriptionChannel<SubscriptionLoobyDriveResponse, typeof variables>({
    query,
    variables,
  });

  try {
    // Start an infinite loop to continuously listen for data from the subscription
    while (true) {
      // Use `take` to wait for the next event (either data or error) from the Apollo subscription channel.
      const { data, error } = (yield take(channel)) as SubscriptionResponse<SubscriptionLoobyDriveResponse>; // Type assertion to inform TypeScript about the expected data structure

      if (error) {
        yield call(handleSagaError, {
          callback: callback?.onError,
          error: processSagaError(error, 'An error occurred in subscription channel'),
          title: 'Subscribe to looby channel',
          skipToast: true,
        });
        break;
      }

      if (isSchDriveLobbyMeeting(data)) {
        let extractData = data?.sch_drive_lobby?.[0];
        if (!extractData.meeting_id || !extractData.meeting) {
          extractData = createMockMeetingData();
        }

        // const extractData = data?.sch_drive_lobby?.[0];
        yield put(subscribeLobbyDriveScheduleDetailsSuccess(extractData));
        yield call(handleSagaSuccess, { callback: callback?.onSuccess, response: extractData });
      }
    }
  } catch (error) {
    yield call(handleSagaError, {
      callback: callback?.onError,
      error: processSagaError(error, 'An error occurred in schDriveLobbyMeetingSubscriptionSagaWorker'),
      title: 'Subscribe to Drive Lobby Meeting',
      skipToast: true,
    });
  } finally {
    // Ensure that the subscription channel is closed after the saga completes
    channel.close();
  }
}

export function* schDriveLobbyMeetingSubscriptionSagaWatcher() {
  yield takeLatest(subscribeLobbyDriveScheduleRequest.type, schDriveLobbyMeetingSubscriptionSagaWorker);
}

export function* fetchDriveOccurrenceSagaWatcher() {
  yield takeLatest(fetchDriveDetails.type, fetchDriveOccurrenceGuestWorker);
}

export function* confirmDriveSlotSagaWatcher() {
  yield takeLatest(confirmDriveSlotRequest.type, confirmDriveSlotWorker);
}

export function* fetchDriveOccurrenceDetailsSagaWatcher() {
  yield takeLatest(fetchDriveOccurrenceDetails.type, fetchDriveOccurrenceDetailsSagaWorker);
}

export function* cancelDriveOccurrenceGuestSagaWatcher() {
  yield takeLatest(cancelDriveOccurrenceGuestRequest.type, cancelDriveOccurrenceGuestSagaWorker);
}

export function* updateDriveMemberStatusSagaWatcher() {
  yield takeLatest(updateDriveMemberStatusRequest.type, updateDriveMemberStatusSagaWorker);
}

export function* fetchHeartBeatLobbySagaWatcher() {
  yield takeLatest(heartBeatStatusRequest.type, fetchHeartBeatLobbySagaWorker);
}

export function* driveRootSaga() {
  yield fork(fetchDriveOccurrenceSagaWatcher);
  yield fork(confirmDriveSlotSagaWatcher);
  yield fork(fetchDriveOccurrenceDetailsSagaWatcher);
  yield fork(cancelDriveOccurrenceGuestSagaWatcher);
  yield fork(schDriveLobbyMeetingSubscriptionSagaWatcher);
  yield fork(updateDriveMemberStatusSagaWatcher);
  yield fork(fetchHeartBeatLobbySagaWatcher);
}
