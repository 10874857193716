import LazyLoaderErrorBoundary from '@components/Errors/LazyLoaderErrorBoundary';

export default async function Loadable() {
  const { CancellationWindowClosedContainer } = await import(
    '@containers/Payment/ErrorScreens/CancellationWindowClosed'
  );
  return {
    Component: CancellationWindowClosedContainer,
    ErrorBoundary: LazyLoaderErrorBoundary,
  };
}
