import type { KeyboardEvent } from 'react';

export const handleEnterKeyPress = (
  event: KeyboardEvent<HTMLDivElement> | KeyboardEvent<HTMLButtonElement> | KeyboardEvent<HTMLSpanElement>,
  callback: (e?: KeyboardEvent) => unknown
) => {
  if (event.keyCode === 13 || event.code === 'Enter') {
    callback(event);
  }
};

/**
 * A utility function to handle keyboard events with a default or custom condition.
 *
 * @param event - The keyboard event object.
 * @param callback - The function to execute if the condition is met.
 * @param condition - (Optional) A custom condition to filter the event. Defaults to check for the Enter key.
 */
export const handleInteractiveEvent = (
  event: KeyboardEvent<HTMLDivElement> | KeyboardEvent<HTMLButtonElement> | KeyboardEvent<HTMLSpanElement>,
  callback: (event: KeyboardEvent) => unknown,
  condition: (event: KeyboardEvent) => boolean = (e) => e.key === 'Enter' // Default condition for Enter key
) => {
  if (condition(event)) {
    // event.preventDefault(); // Prevent scrolling when the Space key is pressed
    callback(event);
  }
};
